import React from 'react';
import About from './page-components/Home/About';
import Clients from './page-components/Home/Clients';
import Consultation from './page-components/Home/Consultation';
import Reviews from './page-components/Home/Reviews';
import TechStack from './page-components/Home/TechStack';

export enum PageSections {
  ReviewsSection = 'shared.review-component',
  TechStackSection = 'shared.tech-stack-component',
  ClientsSection = 'shared.partners-component',
  ConsultationSection = 'homepage.consultation',
  AboutSection = 'homepage.sapients',
}

const pageComponents = {
  [PageSections.ReviewsSection]: Reviews,
  [PageSections.TechStackSection]: TechStack,
  [PageSections.ClientsSection]: Clients,
  [PageSections.ConsultationSection]: Consultation,
  [PageSections.AboutSection]: About,
};

type PageSectionProps = {
  name: PageSections,
  props: object,
  children?: React.ReactNode
};
export const getPageSection = ({ name, props, children }: PageSectionProps) => {
  const SectionComponent = pageComponents[name as PageSections];
  // @ts-ignore props is missing error because of props destructuring
  return SectionComponent && <SectionComponent {...props}>{children}</SectionComponent>;
};
