import { Splide, SplideTrack } from '@splidejs/react-splide';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import React from 'react';
import { theme } from '../store/theme';

type SliderProps = {
  settings: {
    [x: string]: any,
  },
  classNames: {
    [x: string]: string,
  },
  children: React.ReactNode,
};

const Slider = ({
  settings, classNames, children,
}: SliderProps) => {
  const appTheme = useAtomValue(theme);

  return (
    <Splide
      hasTrack={false}
      options={settings}
      className={classNames.slider}
    >
      <SplideTrack className={classNames.track}>
        {children}
      </SplideTrack>
      {settings.arrows && (
        <div className={classnames(classNames.navigation, 'splide__arrows')}>
          <button
            type='button'
            className={classnames('splide__arrow splide__arrow--prev', classNames.arrow, classNames.prevArrow)}
          >
            <Image
              fill
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt=''
            />
          </button>
          <button
            type='button'
            className={classnames('splide__arrow splide__arrow--next', classNames.arrow, classNames.nextArrow)}
          >
            <Image
              fill
              src={`/media/slider-arrow-right-${appTheme}.svg`}
              alt=''
            />
          </button>
        </div>
      )}
    </Splide>
  );
};

export default Slider;
