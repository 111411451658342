import { IReviewComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import AnimatedTitle from '../../../components/AnimatedTitle';
import { HeaderItemType } from '../../../components/Header/interfaces';
import ClutchReviews from './ClutchReviews';
import styles from './Reviews.module.scss';
import ReviewsTextSlider from './ReviewsTextSlider';
import ReviewsVideos from './ReviewsVideos';

const Reviews = ({ data, sectionId }: { data: IReviewComponent, sectionId?: HeaderItemType }) => (
  <section
    className={styles.reviews}
    id={sectionId}
  >
    <header className={classnames(styles.reviews__header)}>
      <h2 className={classnames('sectionTitle', styles.title)}>
        <AnimatedTitle title={data?.title} />
      </h2>
      <p className={classnames('sectionSubtitle', styles.text)}>{data?.description}</p>
    </header>
    <div className={styles.reviews__content}>
      {data.showVideoReviews && <ReviewsVideos data={data?.videoReviews} />}
      {data.showTextReviews && <ReviewsTextSlider data={data?.textReviews} />}
      <span className={styles.ellipse_right} />
      <ClutchReviews />
      <span className={styles.ellipse_left} />
    </div>
  </section>
);

export default Reviews;
