import { useAtomValue } from 'jotai';
import { theme } from '../../../../store/theme';

import styles from './ClutchReviews.module.scss';

const ClutchReviews = () => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={styles.review}>
      <img
        src={`/media/clutch_bg_${appTheme}.svg`}
        alt='clutch'
        className={styles.clutchBg}
      />
      <img
        src={`/media/clutch_${appTheme}.svg`}
        alt='clutch'
        width={172}
        height={49}
        className={styles.logo}
      />
      <div className={styles.content}>
        <div className={styles.stars}>
          {new Array(5).fill(0).map((item, index) => (

            <svg // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.star}
            >
              <use
                xlinkHref='/media/star.svg#star'
                href='/media/star.svg#star'
              />
            </svg>
          ))}
        </div>
        <p className={styles.rating}>
          5 out of 5, based on
          {' '}
          <a
            target='_blank'
            href='https://clutch.co/profile/sapientpro#highlights'
            className={styles.rating__link}
            rel='noreferrer'
          >
            31 reviews
          </a>
        </p>
        <p className={styles.text}>
          We aim to help our clients build successful products, improve business processes,
          and provide a competitive advantage. Our software development company has proven
          to be a reliable partner by receiving a 5/5 score on Clutch.co.
        </p>
      </div>
    </div>
  );
};

export default ClutchReviews;
