import { IMemberUs } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './Team.module.scss';

interface TeamMemberCardProps {
  teamMember: IMemberUs
}

const TeamMemberCard = ({ teamMember }: TeamMemberCardProps) => (
  <div className={styles.teamMemberCard}>
    <div className={styles.teamMemberCard__imageWrapper}>
      <Image
        src={teamMember.image.url}
        alt={teamMember.name}
        width={500}
        height={680}
      />
    </div>
    <div className={styles.teamMemberCard__content}>
      <div className={styles.teamMemberCard__nameWrapper}>
        <p className={styles.teamMemberCard__name}>{teamMember.name}</p>
        <p className={styles.teamMemberCard__position}>{teamMember.position}</p>
        <p className={styles.teamMemberCard__description}>{teamMember.description}</p>
      </div>
    </div>
  </div>
);

interface ITeamProps {
  teamMembers: IMemberUs[]
}

const Team = ({ teamMembers }: ITeamProps) => (
  <section className={classNames(styles.teamMembersWrapper, 'container')}>
    <div className={styles.teamMembers}>
      {!!teamMembers?.length && teamMembers.map(teamMember => (
        <TeamMemberCard
          key={teamMember.id}
          teamMember={teamMember}
        />
      ))}
    </div>
  </section>
);

export default Team;
