import { ISapientsUs } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';

import { useAtomValue, useSetAtom } from 'jotai';
import Image from 'next/image';
import { useRef } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import { HeaderItemType } from '../../../components/Header/interfaces';
import isContactModalOpen from '../../../store/contactForm';
import clientScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import styles from './About.module.scss';
import Team from './Team';

const About = ({ data, sectionId }: { data: ISapientsUs, sectionId?: HeaderItemType }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const deviceSize = useAtomValue<ScreenSize>(clientScreenSize);
  const setContactsModalOpen = useSetAtom(isContactModalOpen);

  return (
    <section
      className={styles.about}
      ref={sectionRef}
      id={sectionId}
    >
      <div className={styles.about__content}>
        <header className={classnames(styles.header, 'container')}>
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data?.title} />
          </h2>
          <p className={classnames('sectionSubtitle', styles.text)}>
            {data?.description}
          </p>
        </header>

        <Team teamMembers={data?.teams?.[0]?.members} />

        <div className={styles.additionalInfoWrapper}>
          <div className={classnames(styles.textContent, 'container')}>
            <p className={classnames('sectionSubtitle', styles.text)}>
              {data?.bottomDescription}
            </p>
            <h3 className={styles.slogan}>
              {data?.bottomQuote}
            </h3>
          </div>
          <Button
            variant={ButtonVariants.OUTLINED}
            className={styles.ctaButton}
            onClick={() => setContactsModalOpen(true)}
            icon={(
              <svg>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
            )}
            iconPosition='right'
            iconSize={deviceSize <= ScreenSize.DESKTOP_SMALL ? { width: 24, height: 24 } : { width: 32, height: 32 }}
            size={deviceSize <= ScreenSize.DESKTOP_SMALL ? 'normal' : 'big'}
          >
            BOOST your project
          </Button>
        </div>
      </div>
      <Image
        width={975}
        height={883}
        src='/media/Home/team-ellipse.svg'
        alt='ellipse background'
        className={styles.ellipse}
      />
      <div className={styles.blurBg} />
    </section>
  );
};

export default About;
