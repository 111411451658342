import { ITechStack, ITechStackComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { theme } from '../../../store/theme';

import styles from './TechStack.module.scss';

type TechStackProps = {
  data: ITechStackComponent,
  contentRef: React.RefObject<HTMLDivElement>,
  className?: string,
  children?: React.ReactNode,
};

const TechStackRow = ({ techStack }: { techStack: ITechStack }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div
      className={styles.row}
      key={techStack.id}
    >
      <h3 className={styles.row__title}>{techStack.techStack}</h3>

      <div className={classnames(styles.row__technologies)}>
        {techStack?.technologies?.map(technology => (
          <div
            className={styles.item}
            key={technology.id}
          >
            <div className={styles.item__image}>
              <Image
                src={technology?.image?.[appTheme]?.url}
                alt={technology?.image?.[appTheme]?.alternativeText}
                width={24}
                height={24}
              />
            </div>
            <p className={styles.item__title}>{technology?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const TechStack = React.forwardRef<HTMLElement, TechStackProps>(({
  data, contentRef, className, children,
}, ref) => {
  const [isAnimationStarted, setIsAnimationStarted] = useState<boolean>(false);
  const activeSection = useAtomValue(activeHomeSection);
  const deviceSize = useAtomValue(deviceScreenSize);

  useEffect(() => {
    setIsAnimationStarted(activeSection === Section.THIRD);
  }, [activeSection]);

  return (
    <section
      ref={ref}
      className={classnames(className, styles.techStackWrapper)}
    >
      <div className='container'>
        <div className={styles.techStack}>
          <div className={styles.ellipse} />
          <div className={styles.techStack__content}>
            {children}
            <header className={styles.techStack__header}>
              <Image
                className={styles.graphElementImageTitle}
                src='/media/Home/graphElementUnderTitle.svg'
                alt=''
                width={183}
                height={65}
              />
              <h2 className={classnames('sectionTitle', styles.title)}>
                <AnimatedTitle title={data?.title} />
              </h2>
              <p className={classnames('sectionSubtitle', styles.techStack__text)}>
                {data?.content}
              </p>
            </header>

            <div className={styles.content__wrap}>
              {deviceSize > ScreenSize.TABLET_PORTRAIT && (
                <div className={classnames(styles.techStack__line, {
                  [styles.techStack__line_animate]: isAnimationStarted,
                })}
                />
              )}
              <div
                className={styles.content}
                ref={contentRef}
              >
                {data?.tech_stacks?.map(techStack => (
                  <React.Fragment key={techStack.id}>
                    <TechStackRow techStack={techStack} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <Image
            className={styles.graphElementImageBottom}
            src='/media/Home/graphElementBottom.svg'
            alt=''
            width={127}
            height={146}
          />
        </div>
      </div>
    </section>
  );
});

export default TechStack;
