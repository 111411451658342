import classnames from 'classnames';
import { useAtom } from 'jotai';
import React from 'react';
import isChatbotOpen from '../store/chatbot';
import styles from './ChatButton.module.scss';

const ChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useAtom(isChatbotOpen);

  return (
    <button
      type='button'
      className={classnames(styles.button, {
        [styles.button_openedChat]: isChatOpen,
      })}
      aria-label='open chat'
      onClick={() => setIsChatOpen(!isChatOpen)}
    >
      <div className={styles.circle__border}>
        <div className={styles.circle}>
          {isChatOpen ? (
            <svg className={styles.closeChatButton}>
              <use
                xlinkHref='/media/close.svg#closeSVG'
                href='/media/close.svg#closeSVG'
              />
            </svg>
          ) : (
            <div className={styles.animationWrap}>
              <div className={styles.animation}>
                {/* eslint-disable-next-line react/no-array-index-key */}
                {new Array(99).fill(null).map((item, index) => <span key={index} />)}
              </div>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default ChatButton;
