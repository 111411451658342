import React from 'react';

import Button, { ButtonVariants } from './Button';

import styles from './GlassCard.module.scss';

interface IGlassCardProps {
  title: string | JSX.Element;
  textContent: string;
  buttonText: string;
  onButtonClick: () => void;
}

const GlassCard = ({
  title, textContent, buttonText, onButtonClick,
}: IGlassCardProps) => (
  <article className={styles.card}>
    <div className={styles.card__overlay} />
    <div className={styles.card__content}>
      <p className={styles.card__title}>{title}</p>
      <p className={styles.card__text}>{textContent}</p>
      <Button
        onClick={onButtonClick}
        variant={ButtonVariants.OUTLINED}
        className={styles.card__button}
        icon={(
          <svg>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
        )}
        iconPosition='right'
        iconSize={{ width: 24, height: 24 }}
      >
        {buttonText}
      </Button>
    </div>
  </article>
);

export default GlassCard;
