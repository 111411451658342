import { IServicesUsComponent } from '@sapientpro/sapientpro-data-models';
import Image from 'next/image';
import styles from './Expertises.module.scss';

type ExpertiseCardProps = {
  service: IServicesUsComponent;
};

const ExpertiseCard = ({ service }: ExpertiseCardProps) => (
  <article
    className={styles.expertiseCard}
    data-expertise-service-id={`expertise-service-${service.id}`}
  >
    <div className={styles.expertiseCard__icon}>
      <Image
        src={service.image.url}
        alt={service.title}
        width={300}
        height={120}
      />
    </div>
    <p className={styles.expertiseCard__title}>{service.title}</p>
    <p className={styles.expertiseCard__text}>{service.description}</p>
  </article>
);

export default ExpertiseCard;
