import { IReviewQuote } from '@sapientpro/sapientpro-data-models';
import { SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useAtomValue } from 'jotai';
import React from 'react';
import { MouseParallax } from 'react-just-parallax';
import Ellipse, { EllipseVariants } from '../../../../components/Ellipse';
import Slider from '../../../../components/Slider';
import deviceScreenSize, { ScreenSize } from '../../../../store/deviceScreenSize';
import ReviewsTextSlide from './ReviewsTextSlide';
import styles from './ReviewsTextSlider.module.scss';

const settings = {
  focus: 0,
  perPage: 1,
  perMove: 1,
  pagination: true,
  arrows: false,
  type: 'loop',
  classes: {
    pagination: `splide__pagination ${styles.pagination}`,
    page: `splide__pagination__page ${styles.pagination__item}`,
  },
};

const ReviewsTextSlider = ({ data }: { data: IReviewQuote[] }) => {
  const deviceSize = useAtomValue(deviceScreenSize);

  return (
    <div className={styles.reviews}>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.05}
      >
        <Ellipse
          variant={EllipseVariants.DRAW}
          size={deviceSize <= ScreenSize.TABLET_PORTRAIT ? 516 : 720}
          className={styles.parallax__draw}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.12}
      >
        <Ellipse
          variant={EllipseVariants.NOISE}
          size={94}
          className={styles.parallax__noise}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.08}
      >
        <Ellipse
          variant={EllipseVariants.BORDER}
          size={deviceSize <= ScreenSize.TABLET_PORTRAIT ? 28 : 40}
          className={styles.parallax__border}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.02}
      >
        <Ellipse
          variant={EllipseVariants.GRIBS}
          size={490}
          className={styles.parallax__gribs}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.1}
      >
        <Ellipse
          variant={EllipseVariants.GRAPH}
          size={168}
          className={styles.parallax__graph}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.1}
      >
        <Ellipse
          variant={EllipseVariants.CUBE}
          size={225}
          className={styles.parallax__cube}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.1}
      >
        <Ellipse
          variant={EllipseVariants.GRADIENT}
          size={59}
          className={styles.parallax__gradient}
        />
      </MouseParallax>
      <MouseParallax
        isAbsolutelyPositioned
        strength={0.1}
      >
        <Ellipse
          variant={EllipseVariants.BORDER}
          size={40}
          className={styles.parallax__borderNoise}
        />
      </MouseParallax>

      {data && (
        <Slider
          settings={settings}
          classNames={{
            slider: styles.slider,
            track: styles.track,
            navigation: styles.navigation,
            arrow: styles.arrow,
            prevArrow: styles.arrow_prev,
            nextArrow: styles.arrow_next,
          }}
        >
          {data.map((review) => (
            <SplideSlide key={review.id}>
              <ReviewsTextSlide
                className={styles.slide}
                review={review}
              />
            </SplideSlide>
          ))}
        </Slider>
      )}
    </div>
  );
};
export default ReviewsTextSlider;
