'use client';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import Script from 'next/script';
import React from 'react';
import isChatbotOpen from '../store/chatbot';
import styles from './Chatbot.module.scss';
import ChatButton from './ChatButton';

export const openChatSelector = 'openChat';

const setupLandbot = () => {
  // @ts-ignore
  // eslint-disable-next-line no-new
  const bot = new Landbot.Container({
    container: '#landbot',
    // configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1665592-06J67LPO0IHJ0MV3/index.json',
    configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1732453-B8I1NDADLF8O8RFZ/index.json',
  });

  document.addEventListener('click', (event) => {
    const eventTarget = event.target as HTMLElement;
    if (eventTarget.closest(`.${openChatSelector}`)) {
      bot.open();
    }
  });
};
const Chatbot = () => {
  const [isChatOpen, setIsChatOpen] = useAtom(isChatbotOpen);

  return (
    <>
      <ChatButton />
      <div className={classnames(styles.chatbot, {
        [styles.open]: isChatOpen,
      })}
      >
        <button
          type='button'
          aria-label='Close chatbot'
          onClick={() => setIsChatOpen(false)}
          className={styles.closeChatbotButton}
        >
          <svg>
            <use
              xlinkHref='/media/menuClose.svg#menuCloseSVG'
              href='/media/menuClose.svg#menuCloseSVG'
            />
          </svg>
        </button>
        <div
          id='landbot'
          className={styles.chat}
        />
      </div>
      <Script
        strategy='lazyOnload'
        src='https://static.landbot.io/landbot-3/landbot-3.0.0.js'
        onLoad={setupLandbot}
      />
    </>
  );
};

export default Chatbot;
