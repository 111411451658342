import classnames from 'classnames';
import React from 'react';
import styles from './AnimatedBallButton.module.scss';
import { openChatSelector } from './Chatbot';

type AnimatedBallButtonProps = {
  className?: string,
  setContactsModalOpen: (value: boolean) => void,
};
const AnimatedBallButton = ({ className, setContactsModalOpen }: AnimatedBallButtonProps) => (
  <button
    type='button'
    className={classnames(styles.button, className, openChatSelector)}
    onClick={() => setContactsModalOpen(true)}
  >
    <div className={styles.content}>
      Start Project
      <svg className={styles.button__icon}>
        <use
          xlinkHref='/media/icon-nest.svg#iconNest'
          href='/media/icon-nest.svg#iconNest'
        />
      </svg>
    </div>
  </button>
);

export default AnimatedBallButton;
