import { ICaseResponse } from '@sapientpro/sapientpro-data-models';
import Image from 'next/image';
import Link from 'next/link';
import Button, { ButtonVariants } from '../../components/Button';
import { Theme } from '../../store/theme';
import styles from './CaseCard.module.scss';

type CaseCardProps = {
  data: ICaseResponse;
  showViewMoreLink?: boolean;
};

const CaseCard = ({ data, showViewMoreLink }: CaseCardProps) => (
  <article className={styles.case}>
    <div
      className={styles.image}
    >
      <Image
        src={data?.image?.url}
        alt={data?.image?.alternativeText || ''}
        width={608}
        height={388}
      />
    </div>
    <div className={styles.content}>
      <header>
        {data?.projectName && <h3 className={styles.projectName}>{data.projectName}</h3>}
        <h2
          className={styles.title}
        >
          {data.title}
        </h2>
      </header>
      <p className={styles.description}>
        {data.description}
      </p>
      {!!data?.technologies?.length && (
        <div className={styles.technologies}>
          <p className={styles.technologies__title}>Technologies</p>
          <div className={styles.technologies__content}>
            {data.technologies.map(technology => (
              <div
                className={styles.technology}
                title={technology.name}
                key={technology.id}
              >
                <Image
                  width={32}
                  height={32}
                  src={technology?.image?.[Theme.DARK]?.url ? technology.image[Theme.DARK].url : ''}
                  alt={technology.name}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {showViewMoreLink && (
      <Link href={`/cases/${data.slug}`}>
        <Button
          className={styles.viewButton}
          variant={ButtonVariants.TRANSPARENT}
          icon={(
            <svg>
              <use
                xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                href='/media/arrow-right-bold.svg#arrowRight'
              />
            </svg>
              )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
        >
          View Project
        </Button>
      </Link>
      )}
    </div>
  </article>
);

export default CaseCard;
