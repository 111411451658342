import { IServicesUsHomepage } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Image from 'next/image';
import React from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import GlassCard from '../../../components/GlassCard';
import clientDeviceSize, { ScreenSize } from '../../../store/deviceScreenSize';
import ExpertiseCard from './ExpertiseCard';
import styles from './Expertises.module.scss';
import isContactModalOpen from '../../../store/contactForm';
import { HeaderItemType } from '../../../components/Header/interfaces';

interface ExpertisesProps {
  data: IServicesUsHomepage;
  className?: string;
  sectionId?: HeaderItemType;
}

const Expertises = React.forwardRef<HTMLElement, ExpertisesProps>(({ data, className, sectionId }, ref) => {
  const services = data?.items ?? [];
  const splitArrayIndex: number = services.length % 2 > 0 ? Math.ceil(services.length / 2) : (services.length + 1) / 2;
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const setContactsModalOpen = useSetAtom(isContactModalOpen);

  return (
    <section
      ref={ref}
      id={sectionId}
      className={classNames(styles.expertises, 'container', className)}
    >

      <Image
        alt=''
        src='/media/Home/Expertise/graphElementTop.svg'
        width={41}
        height={25}
        className={styles.graphElementImageTop}
      />

      <Image
        alt=''
        src='/media/Home/Expertise/graphElementBottom.svg'
        width={127}
        height={146}
        className={styles.graphElementImageBottom}
      />

      <div className={styles.expertises__column}>

        <h2 className={styles.expertises__title}>
          <AnimatedTitle title={data.title} />
        </h2>
        {deviceSize !== ScreenSize.MOBILE && (
          <span className={styles.expertises__ellipses}>
            <Image
              alt=''
              src='/media/expertises/ellipse.svg'
              width={497}
              height={383}
            />
          </span>
        )}
        {services.slice(0, splitArrayIndex).map((service) => (
          <ExpertiseCard
            key={service.id}
            service={service}
          />
        ))}
        <Image
          className={styles.graphElementImage}
          src='/media/Home/graphElement.svg'
          alt=''
          width={284}
          height={404}
        />
        <img
          className={styles.graphElementImageSmall}
          src='/media/Home/graphElementSmall.svg'
          alt=''
          width={100}
          height={127}
        />
      </div>
      <div className={styles.expertises__column}>
        {services.slice(splitArrayIndex).map((service) => (
          <ExpertiseCard
            key={service.id}
            service={service}
          />
        ))}
        <GlassCard
          onButtonClick={() => setContactsModalOpen(true)}
          title={'Ready to discuss\n your project?'}
          textContent='Talk to our experts to get an estimate and build a market&#8209;winning solution for your business.'
          buttonText='Let’s start'
        />
      </div>

    </section>
  );
});

export default Expertises;
