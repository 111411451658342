import { IReviewQuote } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import Image from 'next/image';
import { theme } from '../../../../../store/theme';
import styles from './ReviewsTextSlide.module.scss';

type ReviewsTextSlideProps = {
  review: IReviewQuote,
  className?: string,
};
const ReviewsTextSlide = ({ review, className }: ReviewsTextSlideProps) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classnames(styles.slide, className)}>
      <div className={styles.slide__content}>
        <div className={styles.author}>
          <div className={styles.author__avatar}>
            <Image
              width={100}
              height={100}
              src={review.author.avatar.url}
              alt={review.author.avatar.alternativeText}
            />
          </div>
          <div className={styles.author__info}>
            <p className={styles.author__name}>{review.author.name}</p>
            <p className={styles.author__description}>{review.author.position}</p>
            {(review?.linkedinLink) && (
              <a
                target='_blank'
                aria-label='open linkedin'
                href={review.linkedinLink}
                className={styles.author__linkedinLink}
                rel='noreferrer'
              >
                Linkedin
              </a>
            )}
          </div>
          {review.reviewLink && (
            <a
              href={review.reviewLink}
              target='_blank'
              className={styles.author__clutch}
              rel='noreferrer'
            >
              <img
                src={`/media/clutch_${appTheme}.svg`}
                alt='clutch'
                width={43}
                height={12}
                className={styles.logo}
              />
              Based
            </a>
          )}
        </div>
        <p className={styles.text}>{review.feedBack}</p>
      </div>
      {review.case && (
        <footer className={styles.case}>
          View
          {' '}
          <Link href={`/${review.case.slug}`}>
            {review.case.projectName}
            {' '}
            case
          </Link>
        </footer>
      )}
    </div>
  );
};

export default ReviewsTextSlide;
