import { IPartnersComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useRef } from 'react';
import Image from 'next/image';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../../components/Button';
import { useSectionOffsetScroll } from '../../../hooks/useSectionOffsetScroll';
import clientDeviceSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { theme } from '../../../store/theme';
import styles from './Clients.module.scss';
import isContactModalOpen from '../../../store/contactForm';

const Clients = ({ data }: { data: IPartnersComponent }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionOffset = useSectionOffsetScroll(sectionRef);
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const setContactsModalOpen = useSetAtom(isContactModalOpen);

  let divideCoefficient = deviceSize === ScreenSize.MOBILE ? 8 : 4;

  return (
    <section
      className={styles.clients}
      ref={sectionRef}
    >
      <header className={classnames(styles.header, 'container')}>
        <h2 className={classnames('sectionTitle', styles.title)}>
          <AnimatedTitle title={data.title} />
        </h2>
        <div className={styles.divider} />
        <Button
          variant={ButtonVariants.OUTLINED}
          onClick={() => setContactsModalOpen(true)}
          className={styles.ctaButton}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
        >
          Become a Client
        </Button>
      </header>
      <div className={styles.content}>
        {data?.partners?.length > 0 && data.partners.map((partnerRow, index) => (
          <div
            key={partnerRow[0].id}
            className={classnames(styles.row, {
              [styles.row_even]: index % 2 !== 0,
            })}
            style={
              {
                marginLeft: index % 2 === 0
                  ? `${sectionOffset / divideCoefficient}px`
                  : `${-sectionOffset / divideCoefficient}px`,
                marginRight: index % 2 === 0
                  ? `${-sectionOffset / divideCoefficient}px`
                  : `${sectionOffset / divideCoefficient}px`,
              }
            }
          >
            {partnerRow.map(partner => (
              <div
                className={styles.item}
                key={partner.id}
              >
                <Image
                  src={partner.image[appTheme].url}
                  alt={partner.image[appTheme].alternativeText}
                  width={90}
                  height={40}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Button
        onClick={() => setContactsModalOpen(true)}
        variant={ButtonVariants.OUTLINED}
        className={classnames(styles.ctaButton, styles.ctaButton_mobile)}
        icon={(
          <svg>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
        )}
        iconPosition='right'
        iconSize={{ width: 24, height: 24 }}
      >
        Become a Client
      </Button>
    </section>
  );
};

export default Clients;
