import classNames from 'classnames';

import { ICasesComponent } from '@sapientpro/sapientpro-data-models';

import CaseCard from '../../page-components/Cases/CaseCard';
import AnimatedTitle from '../AnimatedTitle';
import { HeaderItemType } from '../Header/interfaces';
import styles from './Cases.module.scss';

const Cases = ({ casesData, sectionId }: { casesData: ICasesComponent, sectionId: HeaderItemType }) => (
  <section
    className={styles.cases}
    id={sectionId}
  >
    <header className={classNames(styles.header)}>
      <h2 className={classNames('sectionTitle', styles.title)}>
        <AnimatedTitle title={casesData?.title} />
      </h2>
      <p className={classNames('sectionSubtitle', styles.text)}>
        {casesData?.description}
      </p>
    </header>
    <div className={classNames(styles.casesWrapper)}>
      <div className={styles.cases__content}>
        {casesData.cases.map(caseItem => (
          <CaseCard
            data={caseItem}
            key={caseItem.id}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Cases;
