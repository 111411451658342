export enum HeaderItemType {
  EXPERTISE = 'expertise',
  CASES = 'cases',
  TEAM = 'team',
  REVIEWS = 'reviews',
}

export interface IHeaderItem {
  title: string;
  sectionId: HeaderItemType
}
