import classnames from 'classnames';
import parse from 'html-react-parser';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { Fragment, useEffect, useState } from 'react';
import Image from 'next/image';
import AnimatedBallButton from '../../../components/AnimatedBallButton';
import FadeUpAnimatedText from '../../../components/FadeUpAnimatedText';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import { theme } from '../../../store/theme';
import styles from './Hero.module.scss';
import isContactModalOpen from '../../../store/contactForm';

interface HeroProps {
  title: string,
  className?: string,
}

type IFormattedTitle = Array<string | JSX.Element> | string | null;

const Hero = React.forwardRef<HTMLElement, HeroProps>(({ title, className }, ref) => {
  const appTheme = useAtomValue(theme);
  const [formattedTitle, setFormattedTitle] = useState<IFormattedTitle>(null);
  const activeSection = useAtomValue(activeHomeSection);
  const setContactsModalOpen = useSetAtom(isContactModalOpen);

  const imageMarker = '{{titleImage}}';

  useEffect(() => {
    if (title) {
      const result = title.replace(/(\r\n|\r|\n)/g, '<br>');
      let parsedTitle = parse(result) as Array<string | JSX.Element> | string;
      if (Array.isArray(parsedTitle)) {
        parsedTitle = parsedTitle.map((element: string | JSX.Element, index: number) => {
          let isWithAnimation = null;
          if (typeof element === 'string') {
            isWithAnimation = element.match('\\*\\*[^*]+\\*\\*');
          }

          if (typeof element === 'string' && element.indexOf(imageMarker) !== -1) {
            const titleImageFirstIndex = element.indexOf(imageMarker);
            const titleImageLastIndex = titleImageFirstIndex + imageMarker.length;
            return ( // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {element.slice(0, titleImageFirstIndex)}
                <Image
                  src='/media/Home/Hero/hero-element.webp'
                  alt=' '
                  width={140}
                  height={38}
                  className={styles.title__image}
                />
                {element.slice(titleImageLastIndex)}
              </Fragment>
            );
          } else if (typeof element === 'string' && isWithAnimation) {
            const divider = isWithAnimation.index;
            const textFirstPart = element.slice(0, divider);
            const textLastPart = divider !== undefined && divider >= 0 ? element.slice(divider + isWithAnimation[0].length) : '';
            return ( // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {textFirstPart}
                <FadeUpAnimatedText text={isWithAnimation[0].slice(2, isWithAnimation[0].length - 2)} />
                {textLastPart}
              </Fragment>
            );
          } else {
            return element;
          }
        });
      }
      setFormattedTitle(parsedTitle);
    }
  }, [title, appTheme]);

  return (
    <section
      ref={ref}
      className={classnames(styles.hero, className)}
    >
      <div className={styles.backgroundImage__wrapper}>
        <Image
          className={styles.backgroundImage}
          src='/media/Home/Hero/background.svg'
          alt=' '
          width={1232}
          height={709}
          priority
        />
      </div>
      <h1 className={classnames(styles.title, {
        [styles.visible]: activeSection === Section.FIRST,
      })}
      >
        <AnimatedBallButton
          className={styles.ctaButton}
          setContactsModalOpen={setContactsModalOpen}
        />
        {formattedTitle}
      </h1>
    </section>
  );
});

export default Hero;
