'use client';

import {
  ICasesComponent,
  IConsultation,
  IHomepageUsResponse,
  IPartnersComponent,
  IReviewComponent,
  ISapientsUs,
  IServicesUsHomepage,
  ITechStackComponent,
} from '@sapientpro/sapientpro-data-models';
import { useRef } from 'react';
import dynamic from 'next/dynamic';
import Cases from '../../components/Cases';
import { PageSections, getPageSection } from '../../pageBuilder';
import Consultation from './Consultation';
import Expertises from './Expertises';
import Hero from './Hero';
import styles from './Home.module.scss';
import { HeaderItemType } from '../../components/Header/interfaces';

const DynamicReviews = dynamic(() => import('./Reviews'), {
  loading: () => <div />,
});

export enum HomepageSections {
  ExpertiseSection = 'homepage.services-us-hompage',
  TeamSection = 'homepage.sapients-us',
  CasesSection = 'cases.case-component',
  ReviewsSection = 'shared.review-component',
  ClientsSection = 'shared.partners-component',
  ConsultationSection = 'homepage.consultation',
}

const Home = ({ homepageData }: { homepageData: IHomepageUsResponse }) => {
  /* Tracking section in viewport */
  const firstSection = useRef<HTMLElement>(null);
  const thirdSection = useRef<HTMLElement>(null);
  const techStackContentRef = useRef<HTMLDivElement>(null);
  const fourthSection = useRef<HTMLElement>(null);
  const consultationFormRef = useRef<HTMLFormElement>(null);

  const techStackData: ITechStackComponent = homepageData?.content?.find(section => (
    section.__component === PageSections.TechStackSection
  )) as ITechStackComponent;

  const expertiseData: IServicesUsHomepage = homepageData?.content?.find(section => (
    section.__component === HomepageSections.ExpertiseSection
  )) as IServicesUsHomepage;

  const consultationData: IConsultation = homepageData?.content?.find(section => (
    section.__component === HomepageSections.ConsultationSection
  )) as IConsultation;

  const casesData: ICasesComponent = homepageData?.content?.find(section => (
    section.__component === HomepageSections.CasesSection
  )) as ICasesComponent;

  const aboutData: ISapientsUs = homepageData?.content?.find(section => (
    section.__component === HomepageSections.TeamSection
  )) as ISapientsUs;

  const reviewsData: IReviewComponent = homepageData?.content?.find(section => (
    section.__component === HomepageSections.ReviewsSection
  )) as IReviewComponent;

  const clientsData: IPartnersComponent = homepageData?.content?.find(section => (
    section.__component === HomepageSections.ClientsSection
  )) as IPartnersComponent;

  return (
    <main className={styles.main}>
      <Hero
        className={styles.section}
        title={homepageData?.motto ?? ''}
        ref={firstSection}
      />
      <Expertises
        data={expertiseData}
        className={styles.section}
        sectionId={HeaderItemType.EXPERTISE}
      />
      {getPageSection({
        name: PageSections.TechStackSection,
        props: {
          data: techStackData,
          contentRef: techStackContentRef,
          ref: thirdSection,
          className: styles.section,
          type: 'homepage',
        },
      })}

      <Consultation
        className={styles.section}
        ref={fourthSection}
        data={consultationData}
        formRef={consultationFormRef}
        homepageVersion
      />

      <Cases
        casesData={casesData}
        sectionId={HeaderItemType.CASES}
      />

      {aboutData && getPageSection({ name: PageSections.AboutSection, props: { data: aboutData, sectionId: HeaderItemType.TEAM } })}

      {reviewsData && (
        <DynamicReviews
          data={reviewsData}
          sectionId={HeaderItemType.REVIEWS}
        />
      )}
      {clientsData && getPageSection({ name: PageSections.ClientsSection, props: { data: clientsData } })}
    </main>
  );
};

export default Home;
